/* .slick-prev:before,
.slick-next:before {
  background: red;
}

.HelloSlide{
  border: 1px solid red;
} */
.goldenBorder-box{
  margin: 0px -15px;
}
.goldenBorder-box .carousel-inner{
  padding: 0px 15px;
}

.goldenBorder-box-pair{
  margin: 0px -20px;
}
.goldenBorder-box-pair .carousel-inner{
  padding: 0px 25px;
}

.goldenBorder-box-pair .carousel-inner .carousel-item .calIte{
  min-width: 100% !important;
  width: 100%;
}


@media (max-width: 768px) { /* Target screens smaller than 768px (mobile view) */
  .goldenBorder-box-pair .carousel-inner .carousel-item .calIte{
    min-width: 250px !important;
    width: 250px;
  } 
}


.fire-blink-button-zoon {    
  transition: all 0.3s ease-in-out !important;  
  animation: fireBlinkzoon 0.3s infinite alternate ease-in-out !important;
}

@keyframes fireBlinkzoon {
  0% {
    
    box-shadow: 0 0 15px #1601339c , 0 0 20px #6352e8a7;
  }
  100% {
    
    box-shadow: 0 0 10px #1601339c, 0 0 15px #6352e8a7;
  }
}

.fire-blink-button-zoon-fire {
  transition: all 0.3s ease-in-out !important;  
  animation: fireBlinkfire 0.3s infinite alternate ease-in-out !important;
}

@keyframes fireBlinkfire {
  0% {
    
    box-shadow: 0 0 15px rgba(255, 165, 0, 1), 0 0 25px rgba(255, 69, 0, 0.9);
  }
  100% {
    
    box-shadow: 0 0 10px rgba(255, 140, 0, 0.8), 0 0 20px rgba(255, 69, 0, 0.7);
  }
}