@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.1/font/bootstrap-icons.css");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css");
@import url("https://fonts.googleapis.com/css?family=Poppins:400,700,500,600");
@import url('https://fonts.googleapis.com/css2?family=Kaushan+Script&display=swap');

html[dir="rtl"] .offcanvas-start {
    transform: translateX(-100%);
}

.offcanvas-start {
    transform: translateX(100%);
    right: 0;
    left: initial;
}

html[dir="rtl"] .offcanvas-start.show {
    transform: translateX(0);
}

@media only screen and (max-width: 979px) {
    .offcanvas-start {
        transform: translateX(-100%);
    }

    .offcanvas-start.show {
        transform: translateX(0);
        left: 0;
        right: initial;
    }
}

@keyframes bounce {

    0%,
    100% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(1.2);
    }
}

@keyframes shine {
    0% {
        transform: translateX(-30px) rotate(-25deg);
    }

    100% {
        transform: translateX(250px) rotate(-25deg);
    }
}

* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

*::-webkit-scrollbar {
  display: none;
}

.svg-button:hover .svg-path {
    stroke: white !important;
}

.shine {
    position: relative;
    overflow: hidden;
    transition: all 100ms linear;
}

.shine2 {
    position: relative;
    overflow: hidden;
    transition: all 2000ms linear;
}

.shine:hover::after {
    content: '';
    display: block;
    width: 75px;
    height: 175%;
    background: rgba(45, 44, 60, 1);
    background: linear-gradient(90deg, rgba(45, 44, 60, 0) 0%, rgba(45, 44, 60, 1) 25%, rgba(45, 44, 60, 1) 50%, rgba(45, 44, 60, 1) 75%, rgba(45, 44, 60, 0) 100%);
    opacity: 0.5;
    position: absolute;
    top: -20px;
    left: 0;
    animation: shine 200ms linear;
    transform: translateX(250px) rotate(-25deg);
}

.shine2:hover::after {
    content: '';
    display: block;
    width: 55px;
    height: 275%;
    background: rgba(45, 44, 60, 1);
    background: linear-gradient(90deg, rgba(45, 44, 60, 0) 0%, rgba(45, 44, 60, 1) 25%, rgba(45, 44, 60, 1) 50%, rgba(45, 44, 60, 1) 75%, rgba(45, 44, 60, 0) 100%);
    opacity: 0.5;
    position: absolute;
    top: -20px;
    left: 0;
    animation: shine 500ms linear;
    transform: translateX(250px) rotate(-25deg);
}

.carousel-control-prev {
    left: 0;
    right: initial;
}

.carousel-control-next {
    right: 0;
    left: initial;
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
}

.screen a {
    display: contents;
    text-decoration: none;
}

.container-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}

.container-center-horizontal>* {
    flex-shrink: 0;
    pointer-events: auto;
}

.glow {

    border: none;
    /* width: 200px;
    height: 200px; */
    -webkit-animation: glow 5s ease-in-out alternate;
    -moz-animation: glow 5s ease-in-out alternate;
    animation: glow 5s ease-in-out;
}

strong {
    font-weight: bold;
}

.pwaPopup {
    z-index: 99991;
    position: fixed;
    bottom: 0px;
    background: white;
    padding: 14px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    color: #7767f7;
    text-align: center;
}

.pwaPopup button {
    width: 100px;
    border-radius: 50px;
    padding: 10px;
    border: none;
    background: #7767f7;
    color: white;
    margin: 5px;
    margin-top: 15px;
}

#slider::-webkit-slider-thumb {
    animation: bounce 0.5s ease;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

@keyframes glow {
    0% {
        border-width: 1px;
        border: solid 1px #6352e8;
    }

    25% {
        border-width: 0px;
        border: solid 1px white;
    }

    50% {
        border-width: 1px;
        border: solid 1px #6352e8;
    }

    75% {
        border-width: 0px;
        border: solid 1px white;
    }

    100% {
        border-width: 0px;
        border: solid 0px white;

    }


}

.timerBorder {
    border: 1px solid red;
    transform: rotate3d(0, 0, 1, 0deg);
    animation: 2s borderSpin linear 4s forwards;
}

@keyframes borderSpin {
    from {
        transform: rotate3d(0, 0, 1, 0deg);
    }

    to {
        transform: rotate3d(0, 0, 1, 20deg);
    }
}

* {
    box-sizing: border-box;
}

:root {
    --black: #160133;
    --black-haze: #f7f7f7;
    --black-rock: rgba(22, 1, 51, 1);
    --black-rock-2: #130331;
    --blackcurrant: rgba(45, 44, 60, 1);
    --blue-violet: #6352e8;
    --electric-violet: rgba(87, 0, 234, 1);
    --chicago: #5c5c5c;
    --ebb: #e3e3e3;
    --white: #ffffff;
    --light-purple: #D4D0F3;
    --danger: #D80404;
    --success: #04D835;
    --dark-gray: #00000029;
    --pixie-powder: #d9d9d9;
    --yellow-orange: rgba(255, 172, 75, 1);
    --gunsmoke: rgba(134, 134, 134, 1);
    --moon-raker: rgba(212, 208, 243, 1);
    --concrete: rgba(242, 242, 242, 1);
    --color-d80404: #D80404;
    --color-d9d9d9: #D9D9D9;
    --color-04d835: #04D835;
    --color-000000: #000000;
    --color-d4d0f3: #D4D0F3;
    --color-6352e871: #6352E871;
    --color-00000099: #00000099;
    --color-868686: #868686;
    --color-e3e3e3: #E3E3E3;
    --color-f2f2f2: #F2F2F2;
    --color-6352e8: #6352E8;
    --color-160133: #160133;
    --color-ffffff: #FFFFFF;
    --color-5c5c5c: #5C5C5C;
    --color-120b2b: #120B2B;
    --color-23036a: #23036A;
    --color-2d2c3c: #2D2C3C;
    --color-e8526f: #E8526F;
    --color-00000061: #00000061;

    --font-size-s: 7px;
    --font-size-m: 10px;
    --font-size-l: 12px;
    --font-size-xl: 17px;
    --font-size-xxl: 20px;
    --font-size-xxxl: 24px;

    --font-family-poppins: "Poppins", Helvetica;
    --font-family-Poppins: "Poppins", Helvetica;

    /* Font/text values */
    --font-style-normal: normal;
    --font-weight-normal: normal;
    --font-weight-bold: bold;
    --font-size-8: 8px;
    --font-size-9: 9px;
    --font-size-11: 11px;
    --font-size-12: 12px;
    --font-size-13: 13px;
    --font-size-14: 14px;
    --font-size-16: 16px;
    --font-size-17: 17px;
    --font-size-18: 18px;
    --font-size-22: 22px;
    --character-spacing-0: 0px;
    --line-spacing-10: 10px;
    --line-spacing-12: 12px;
    --line-spacing-13: 13px;
    --line-spacing-14: 14px;
    --line-spacing-16: 16px;
    --line-spacing-25: 25px;
    --text-transform-uppercase: uppercase;

    touch-action: pan-x pan-y;
    height: 100%;

}

/* The modal */
.modal {
    display: none;
    /* Hidden by default */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Sit on top */
    left: 0;

    width: 100%;
    /* Full width */
    height: 100%;
    /* Full height */
    overflow: auto;
    /* Enable scroll if needed */
    background-color: rgba(0, 0, 0, 0.4);
    /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    top: 30%;
    margin: 15% auto;
    /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    /* Could be more or less, depending on screen size */
}

.fullscreentext {
    color: #25145c;
    padding: 5px;
    margin-bottom: 20px;
    font-size: 15px;
    text-transform: capitalize;
}

/* Character Styles */
.character-style-1 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-12);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-e8526f);
}

.character-style-2 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-17);
    line-height: var(--line-spacing-12);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-6352e8);
}

.character-style-3 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-8);
    line-height: var(--line-spacing-10);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-868686);
}

.character-style-4 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-12);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-2d2c3c);
}

.character-style-5 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-12);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-6352e8);
}

.character-style-6 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-12);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-6352e8);
}

.character-style-7 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-12);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-5c5c5c);
}

.character-style-8 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-14);
    line-height: var(--line-spacing-25);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-6352e8);
    text-transform: var(--text-transform-uppercase);
}

.character-style-9 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-13);
    line-height: var(--line-spacing-16);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-23036a);
}

.character-style-10 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-11);
    line-height: var(--line-spacing-13);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-00000061);
}

.character-style-11 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-11);
    line-height: var(--line-spacing-13);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-6352e8);
}

.character-style-12 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-14);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-120b2b);
}

.character-style-13 {
    font-family: var(--font-family-poppins);
    font-style: var(--font-style-normal);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-12);
    line-height: var(--line-spacing-14);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-120b2b);
}

:root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #121212;
    --toastify-color-info: #3498db;
    --toastify-color-success: #07bc0c;
    --toastify-color-warning: #f1c40f;
    --toastify-color-error: #e74c3c;
    --toastify-color-transparent: rgba(255, 255, 255, 0.7);

    --toastify-icon-color-info: var(--toastify-color-info);
    --toastify-icon-color-success: var(--toastify-color-success);
    --toastify-icon-color-warning: var(--toastify-color-warning);
    --toastify-icon-color-error: var(--toastify-color-error);

    --toastify-toast-width: auto;
    --toastify-toast-background: #fff;
    --toastify-toast-min-height: 64px;
    --toastify-toast-max-height: 800px;
    --toastify-font-family: sans-serif;
    --toastify-z-index: 9999;

    --toastify-text-color-light: var(--color-6352e8);
    --toastify-text-color-dark: #fff;

    --toastify-text-color-info: #fff;
    --toastify-text-color-success: #fff;
    --toastify-text-color-warning: #fff;
    --toastify-text-color-error: #fff;

    --toastify-spinner-color: #616161;
    --toastify-spinner-color-empty-area: #e0e0e0;

    --toastify-color-progress-info: var(--toastify-color-info);
    --toastify-color-progress-success: var(--toastify-color-success);
    --toastify-color-progress-warning: var(--toastify-color-warning);
    --toastify-color-progress-error: var(--toastify-color-error);
}


body {
    background: var(--color-160133) 0 0% no-repeat padding-box;
}

.progress-bar {
    background: transparent;
}

body.single {
    background: var(--color-f2f2f2) 0 0% no-repeat padding-box;
}

body p,
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
    margin-bottom: 0;
}

body .Toastify__toast-theme--light {
    background: var(--color-ffffff) 0 0% no-repeat padding-box;
    box-shadow: 0 3px 6px #00000029;
    border-radius: 6px;
    opacity: 1;
}

body .Toastify__close-button--light {
    color: var(--color-6352e8) 0 0% no-repeat padding-box;
}

body .tooltip-inner {
    background: var(--color-160133) 0 0% no-repeat padding-box;
    border: 2px solid var(--color-6352e8);
    box-shadow: 0 3px 6px #00000029;
    border-radius: 0 20px 20px 20px;
    opacity: 1;
    font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-spacing-13) var(--font-family-poppins);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-ffffff);
    text-align: center;
    /* width: 300px; */
    padding: 15px !important;
}

body .tooltip-inner::before {
    top: 7px;
    left: -21px;
    border-width: 0;
    content: "ⓘ";
    color: var(--color-6352e8);
    position: absolute;
}

body .bs-tooltip-top .tooltip-arrow::before {
    display: none;
}

.tooltip-inner:empty {
    display: none;
}

.nav-tabs .nav-link[id|="admin-tabs"] {
    color: black;
    background: white;
    margin: 2px;
    opacity: 0.6;
}

.nav-tabs .nav-link.active[id|="admin-tabs"] {
    color: black;
    background: white;
    margin: 2px;
    opacity: 1;
}

p.firebase-emulator-warning+iframe {
    z-index: -1 !important;
    display: none;
}

#modal .Toastify__toast-container--top-center {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

.bg_main {
    background-color: #e3e3e3;
}

.dark_prpl {
    color: #160133;
}

.display4 {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}

.display3 {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}

.titles {
    font-size: 20px;
    color: white;
    text-align: center;
}

/*coin-votes card*/
.coin_card {
    width: 116px;
    height: 200px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}

.coin_name {
    font-size: 13px;
    line-height: 13px;
    color: #160133;
}

.coin_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}

.coin_value {
    font-size: 13px;
    color: #160133;
}

.coin_votes {
    font-size: 11px;
    color: #e3e3e3;
}

.coin_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}

/*pair-votes card*/
.pair_card {
    width: 236px;
    height: 126px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}

.pair_name {
    font-size: 13px;
    line-height: 10px;
    font-weight: 700;
    color: #160133;
}

.pair_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}

.pair_value {
    font-size: 13px;
    color: #160133;
}

.pair_votes {
    font-size: 11px;
    color: #e3e3e3;
}

.pair_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}

/* user card */
.user_card {
    width: 321px;
    height: 76px;
    background-color: white;
    border-radius: 6px;
    padding: 10px;
}

.sm_txt {
    font-size: 10px;
    line-height: 15px;
    color: #666;
}

.dark_prpl_bkgnd {
    background-color: #160133;
    color: #D4D0F3;
    box-shadow: 1px 1px 4px #6352E8;
}

.light_prpl_bkgnd {
    background-color: #D4D0F3;
    color: #160133;
    box-shadow: 1px 1px 4px #6352E8;
}

.profile_top {
    width: 390px;
    height: 163px;
    border-radius: 0 0 87px 0;
    background-color: #6352E8;
    box-shadow: 1px 1px 4px #6352E8;
    background: -webkit-linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
    background: -o-linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
    background: linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
}

.profile_nav {
    width: 390px;
    height: auto;
    background-color: #D4D0F3;
    line-height: 115px;
}

.profile_nav_button {
    width: 50px;
    height: 50px;
    border-radius: 38px;
    background-color: white;
    box-shadow: 1px 1px 4px #6352E8;
    line-height: 50px;
}

.profile_nav_button_active {
    width: 50px;
    height: 50px;
    border-radius: 38px;
    background-color: #6352E8;
    box-shadow: 1px 1px 4px #6352E8;
    line-height: 50px;
}


.profile_nav_button:hover {
    background-color: #D4D0F3;
}

.profile_nav_icon {
    width: 40%;
    height: auto;
}

.username {
    font-size: 16px;
    line-height: 25px;
}

.username_sm {
    font-size: 12px;
    color: black;
}

.badge {
    background-color: #D4D0F3;
    font-weight: lighter;
    color: #6352E8;
    font-size: 10px;
    text-transform: uppercase;
    width: 90px;
    text-align: center;
}

.badge_sm {
    background-color: #6352E8;
    color: #D4D0F3;
    font-size: 8px;
    text-transform: uppercase;
    width: 70px;
    height: 14px;
    line-height: 14px;
    text-align: center;
}

.avatar {
    width: 66px;
    height: 66px;
}

.avatar_sm {
    width: 44px;
    height: 44px;
}

.margine {
    margin-left: 30px;
}

.cp_balance {
    border-radius: 6px;
    width: 212px;
    height: 139px;
    text-align: center;
    text-transform: uppercase;
}

.cp_PAX {
    font-size: 20px;
    line-height: 22px;
}

.cp_Value {
    font-size: 22px;
    line-height: 18px;
}

.cp_wallet {
    font-size: 9px;
    text-transform: none;
}

.cp_level {
    border-radius: 6px;
    width: 204px;
    padding: 3px;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
}

.box_title {
    font-size: 14px;
    font-weight: normal;
}

.pool_box {
    width: 322px;
    height: 76px;
    border-radius: 6px;
    background-color: #6352E8;
    color: white;
    line-height: 76px;
}

.pool_box_info {
    width: 322px;
    height: 40px;
    border-radius: 6px;
    background-color: #160133;
    font-size: 19px;
    line-height: 40px;
}

.text_prpl {
    color: #6352E8;
}

.icon_size {
    width: 18px;
    height: 17px;
    line-height: 17px;
    text-align: center;
}

#circle {
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}

#circle:hover {
    width: 28px;
    height: 28px;
    background: #D4D0F3;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}

/* POPUP */
.popup_card {
    width: 322px;
    height: auto;
    background-color: white;
    border-radius: 6px;
}

.popup_card_tex {
    text-align: center;
    font-size: 18px;
    color: #6352E8;
    vertical-align: middle;
    line-height: 18px;
}

.circle {
    width: 75px;
    height: 75px;
    border-radius: 75%;
    /* border: #6352E8 solid 1px;         */
    /* background-color: red; */
}


/* PROFILE VOTE */

.profile_coin_vote {
    width: 350px;
    /* height: 90px; */
    border-radius: 6px;
    background: white;
}

.profile_coin_vote_txt {
    font-size: 13px;
    line-height: 16px;
    color: #6352E8;
    text-align: left;

}

.coin_vote_timer {
    font-size: 18px;
    line-height: 18px;
    color: #6352E8;
}


.coin_vote_icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e3e3e3;
}


.profile_pair_vote {
    width: 350px;
    height: 152px;
    border-radius: 6px;
    background: white;
    vertical-align: middle;
}

.pair_vote_vs {
    font-size: 14px;
    color: #6352E8;
}


.text-success {
    color: limegreen !important
}

.text-danger {
    color: red !important
}



.bg_main {
    background-color: #e3e3e3;
}

.display4 {
    font-family: 'Poppins', sans-serif;
    font-size: 34px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}

.display3 {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    line-height: 29px;
    text-align: center;
    color: #fff;
    font-weight: 300;
    text-transform: uppercase;
}

.titles {
    font-size: 20px;
    color: white;
    text-align: center;
}

/*coin-votes card*/
.coin_card {
    width: 116px;
    height: 200px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}

.coin_name {
    font-size: 13px;
    line-height: 10px;
    font-weight: 700;
    color: #160133;
}

.coin_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}

.coin_value {
    font-size: 13px;
    color: #160133;
}

.coin_votes {
    font-size: 11px;
    color: #e3e3e3;
}

.coin_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}

/*pair-votes card*/
.pair_card {
    width: 236px;
    height: 126px;
    border-radius: 6px;
    border: #6352E8 solid 1px;
    background-color: white;
}

.pair_name {
    font-size: 13px;
    line-height: 10px;
    font-weight: 700;
    color: #160133;
}

.pair_init {
    font-size: 13px;
    line-height: 12px;
    color: #160133;
}

.pair_value {
    font-size: 13px;
    color: #160133;
}

.pair_votes {
    font-size: 11px;
    color: #e3e3e3;
}

.pair_vote_btn {
    text-decoration: none;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: bold;
    color: #6352E8;
}

/* user card */
.user_card {
    width: 321px;
    height: 76px;
    background-color: white;
    border-radius: 6px;
    padding: 10px;
}

.sm_txt {
    font-size: 10px;
    line-height: 15px;
    color: #666;
}

.dark_prpl_bkgnd {
    background-color: #160133;
    color: #D4D0F3;
    box-shadow: 1px 1px 4px #6352E8;
}

.light_prpl_bkgnd {
    background-color: #D4D0F3;
    color: #160133;
    box-shadow: 1px 1px 4px #6352E8;
}

.profile_top {
    width: 390px;
    height: 163px;
    border-radius: 0 0 87px 0;
    background-color: #6352E8;
    box-shadow: 1px 1px 4px #6352E8;
    background: -webkit-linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
    background: -o-linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
    background: linear-gradient(rgba(55, 18, 179, 1) 0%, rgba(93, 70, 224, 1) 77%, rgba(84, 69, 195, 1) 100%);
}

.profile_nav {
    width: 390px;
    height: auto;
    background-color: #D4D0F3;
    line-height: 115px;
}

.profile_nav_button {
    width: 50px;
    height: 50px;
    border-radius: 38px;
    background-color: white;
    box-shadow: 1px 1px 4px #6352E8;
    line-height: 50px;
}

.profile_nav_button:hover {
    background-color: #D4D0F3;
}

.profile_nav_button:active {
    background-color: #6352E8;
    color: #fff;
}

.profile_nav_icon {
    width: 40%;
    height: auto;
}

.username {
    font-size: 16px;
    line-height: 25px;
}

.username_sm {
    font-size: 12px;
}

.badge {
    background-color: #D4D0F3;
    font-weight: lighter;
    color: #6352E8;
    font-size: 10px;
    text-transform: uppercase;
    width: 90px;
    text-align: center;
}

.badge_sm {
    background-color: #6352E8;
    color: #D4D0F3;
    font-size: 8px;
    text-transform: uppercase;
    width: 70px;
    height: 14px;
    line-height: 14px;
    text-align: center;
}

.avatar {
    width: 66px;
    height: 66px;
}

.avatar_sm {
    width: 44px;
    height: 44px;
}

.margine {
    margin-left: 30px;
}

.cp_balance {
    border-radius: 6px;
    width: 204px;
    height: 139px;
    font-size: 12px;
    text-align: center;
    text-transform: uppercase;
}

.cp_PAX {
    font-size: 18px;
    line-height: 10px;
    font-weight: 300;
}

.cp_Value {
    font-size: 22px;
    font-weight: bold;
}

.cp_wallet {
    font-size: 9px;
    text-transform: none;
}

.cp_level {
    border-radius: 6px;
    width: 204px;
    padding: 3px;
    font-size: 10px;
    line-height: 10px;
    text-transform: uppercase;
}

.box_title {
    font-size: 14px;
    font-weight: normal;
}

.pool_box {
    width: 322px;
    height: 76px;
    border-radius: 6px;
    background-color: #6352E8;
    color: white;
    line-height: 76px;
}

.pool_box_info {
    width: 322px;
    height: 40px;
    border-radius: 6px;
    background-color: #160133;
    font-size: 19px;
    line-height: 40px;
}

.text_prpl {
    color: #6352E8;
}

.icon_size {
    width: 18px;
    height: 17px;
    vertical-align: middle;

}

#circle {
    width: 28px;
    height: 28px;
    background: #fff;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}


#circle:hover {
    width: 28px;
    height: 28px;
    background: #D4D0F3;
    border-radius: 50%;
    border: #6352E8 solid 1px;
}

.Toastify__close-button {
    position: absolute;
    top: 0;
    right: 0;
}

html .accordion-button {
    text-align: left;
}

html[dir="rtl"] .accordion-button {
    text-align: right;
}

.tada {
    -webkit-animation-name: tada;
    animation-name: tada;
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    /* animation: tada 2s ease 0s 8 normal forwards; */
}

/* @-webkit-keyframes tada {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes tada {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
        -webkit-transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
        -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
        transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
} */

.blink {
    animation: myAnim 2s ease 0s infinite normal forwards;
    text-shadow: 1px 1px 2px black;
}

@keyframes myAnim {
    0% {
        animation-timing-function: ease-in;
        opacity: 1;
        transform: translateY(-45px);
    }

    24% {
        opacity: 1;
    }

    40% {
        animation-timing-function: ease-in;
        transform: translateY(-24px);
    }

    65% {
        animation-timing-function: ease-in;
        transform: translateY(-12px);
    }

    82% {
        animation-timing-function: ease-in;
        transform: translateY(-6px);
    }

    93% {
        animation-timing-function: ease-in;
        transform: translateY(-4px);
    }

    25%,
    55%,
    75%,
    87% {
        animation-timing-function: ease-out;
        transform: translateY(0px);
    }

    100% {
        animation-timing-function: ease-out;
        opacity: 1;
        transform: translateY(0px);
    }
}

.textblink {
    animation: enhanced-blink-animation 1.5s infinite;
}

@keyframes enhanced-blink-animation {
    0% {
        opacity: 1;        
        transform: scale(1); /* Original size */
    }
    25% {
        opacity: 0.5;        
        transform: scale(1.1); /* Slightly larger */
    }
    40% {
        opacity: 0;        
        transform: scale(0.8); /* Slightly smaller */
    }
    50% {
        opacity: 0.5;        
        transform: scale(0.9); /* Slightly smaller */
    }
    75% {
        opacity: 0.75;        
        transform: scale(1.05); /* Slightly larger again */
    }
    100% {
        opacity: 1;        
        transform: scale(1); /* Back to original size */
    }
}



.confetti-button {
    position: relative;
    transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
}

.confetti-button:focus {
    outline: 0;
}

.confetti-button:before,
.confetti-button:after {
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
}

.confetti-button:before {
    display: none;
    top: -75%;
    background-image: radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, transparent 20%, #6352E8 20%, transparent 30%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, transparent 10%, #6352E8 15%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
}

.confetti-button:after {
    display: none;
    bottom: -75%;
    background-image: radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, transparent 10%, #6352E8 15%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%), radial-gradient(circle, #6352E8 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
}

.confetti-button:active {
    transform: scale(0.9);
}

.confetti-button.animate:before {
    display: block;
    animation: topBubbles ease-in-out 0.75s forwards;
}

.confetti-button.animate:after {
    display: block;
    animation: bottomBubbles ease-in-out 0.75s forwards;
}

/* Hide the default icon */
.tooltip-arrow {
    display: none !important;
}

.tooltip-inner::before {
    display: none !important;
}

.ellipsisText {
    display: inline-block;
    max-width: 100px;
    /* Adjust as needed */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }

    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }

    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }

    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }

    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bull_shake_left {

    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 0 50%;
    }

    10% {
        transform: rotate(2deg);
    }

    20%,
    40%,
    60% {
        transform: rotate(-4deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(4deg);
    }

    80% {
        transform: rotate(-2deg);
    }

    90% {
        transform: rotate(2deg);
    }
}

@keyframes bull_shake_right {

    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 100% 50%;
    }

    10% {
        transform: rotate(2deg);
    }

    20%,
    40%,
    60% {
        transform: rotate(-4deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(4deg);
    }

    80% {
        transform: rotate(-2deg);
    }

    90% {
        transform: rotate(2deg);
    }
}

/* //rotate y axis 360 */

.rotateY360 {
    color: red;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

/* //popup style */

.popupStyle {
    border-radius: 60px 0px 60px 60px !important;
}

.popupStyleContainer {
    position: absolute;
    top: -10px !important;

}

.stayInGamePopupStyle {
    border-radius: 100px 0px 100px 100px !important;
    width: 25em;
    height: 14em;
}

.capitalize {
    text-transform: capitalize;
}

.placeholderColor::placeholder {
    color: white !important;
}

iframe[style^="position"] {
    display: none !important;
}

.loading {
    clip-path: inset(0 1.2ch 0 0);
    animation: l 1s steps(5) infinite;
}

@keyframes l {
    to {
        clip-path: inset(0 -1ch 0 0)
    }
}

.popupStyleContainer .swal2-close:hover {
    color: grey !important;
}

.stayInGamePopupStyle .swal2-close:hover {
    color: grey !important;
}

.TwoFA {
    margin-bottom: 20px;
    font-size: 1rem;
}

.circleBtn {
    width: 200px;
    border: 3px solid white;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 50px;
    background: linear-gradient(rgb(82, 99, 184) 0%, rgb(178, 102, 245) 100%);
    animation: 1s ease 0s infinite normal none running zoom-in-zoom-out;
    transition: background 1s ease 0s;
}

.circleBtn .inn_btn {
    background: white;
    border: 1px solid white;
    border-radius: 50px;
    padding: 3px;
    margin: 7px 0px;
    width: 174px;
    color: rgb(184, 105, 252);
    font-family: "Lilita One";
    font-size: 18px;
    transition: color 1s ease 0s;
    animation: 1s ease 0s infinite normal none running colorText;
    justify-content: center;
    align-items: center;
    text-align: center;

}


@keyframes zoom-in-zoom-out {
    0% {
        background: linear-gradient(180deg, rgba(82, 99, 184, 1) 0%, rgba(178, 102, 245, 1) 100%);
        color: #B869FC;
    }

    100% {
        background: linear-gradient(180deg, rgba(212, 176, 92, 1) 0%, rgba(243, 236, 60, 1) 100%);
        color: #DAA636;
    }
}

@keyframes colorText {
    0% {
        color: #B869FC;
    }

    100% {

        color: #DAA636;
    }
}


.shaped-btn-row .btn-shaped {
    height: 26px;
    border: 1px solid var(--blue-violet);
    background: white;
    min-width: 0px;
    box-shadow: rgb(67, 47, 229) 0px 2px 1px, rgba(0, 0, 0, 0.22) 0px 6px 12px;
    transition: all 0.2s ease 0s;
    flex-direction: column;
    border-radius: 38px;
    border-top-right-radius: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6352e8;
    font-weight: 600;
    flex: 0 0 76px;
    max-width: 76px;
    margin-bottom: 5px;
    font-size: 10px;
}

.shaped-btn-row .btn-shaped.left-side {
    border-radius: 38px !important;
    border-top-left-radius: 0px !important;
}


.custom-circle-progress {
    position: relative;
    background: transparent;
    text-align: center;
    position: absolute;
    right: -36px;
    top: 0px;
}


.custom-circle-progress .gift-icon {
    position: absolute;
    top: 54%;
    left: 17px;
    width: 35px;
    z-index: 2;
    color: white;
    transform: translateY(-50%);
}

.custom-circle-progress .gift-icon-css {
    position: relative;
    top: 2px;
    left: 5px;
    width: 35px;
    z-index: 2;
    transform: initial;
}

.custom-circle-progress .gift_animation-css {
    position: absolute;
    top: 15px;
    left: 12px;
    width: 30px;
    z-index: 2;
}

/* .gift_animation-css {
    animation:bull_shake_left 2s ease 0s infinite;
} */

.custom-circle-progress .progress-cs {
    width: 60px;
    height: 60px;
    font-size: 0px;
    color: #fff;
    border-radius: 9999px;
    overflow: hidden;
    position: relative;
    background: #ffffff;
    text-align: center;
    flex: 0 0 60px;
    max-width: 60px;
}

.custom-circle-progress .progress-cs::after {
    content: "%";
}

.custom-circle-progress .progress-cs .title {
    position: relative;
    z-index: 100;
}

.custom-circle-progress .progress-cs .overlay {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #ffffff
}

.custom-circle-progress .progress-cs .left,
.custom-circle-progress .progress-cs .right {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 4px solid #6352e8;
    border-radius: 100px 0px 0px 100px;
    border-right: 0;
    transform-origin: right;
}

.custom-circle-progress .progress-cs .left {
    animation: load1 1s linear forwards;
}

.custom-circle-progress .progress-cs:nth-of-type(2) .right,
.custom-circle-progress .progress-cs:nth-of-type(3) .right {
    animation: load2 .5s linear forwards 1s;
}

.custom-circle-progress .progress-cs:last-of-type .right,
.custom-circle-progress .progress-cs:first-of-type .right {
    animation: load3 .8s linear forwards 1s;
}
.custom-swal-popup{
    border-radius: 100px 0px 100px 100px;
    width: 35%;
    height: 250px;
}

.custom-swal-popup{
    border-radius: 100px 0px 100px 100px;
    width: 35%;
    height: 250px;
}

@keyframes load1 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(180deg);
    }
}

@keyframes load2 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(270deg);
    }
}

@keyframes load3 {
    0% {
        z-index: 100;
        transform: rotate(180deg);
    }

    100% {
        z-index: 100;
        transform: rotate(315deg);
    }
}


@media only screen and (max-width: 979px) {

    .mobileHeader .custom-circle-progress .progress-cs {
        width: 45px;
        height: 45px;
        flex: 0 0 45px;
        max-width: 45px;
    }

    .mobileHeader .custom-circle-progress .gift-icon {
        position: absolute;
        top: 51%;
        left: 16px;
        width: 30px;
        z-index: 2;
        color: white;
        font-size: 10px;
        transform: translateY(-50%);
    }

    .custom-circle-progress .gift_animation-css {
        position: absolute;
        top: 15px;
        left: 6px;
        width: 30px;
        z-index: 2;
    }

    .mobileHeader .custom-circle-progress .gift-icon-css {
        width: 30px;
        top: 5px;
        left: 12px;
    }

    .mobileHeader .gift_animation-css .coinIcon>div {
        width: 14px;
        height: 15px;
        font-size: 7px;
        right: -20px;
        top: -7px;
    }

    .mobileHeader .custom-circle-progress .progress-cs .left,
    .mobileHeader .custom-circle-progress .progress-cs .right {
        border-width: 3px;
        border-radius: 100px 0px 0px 100px;
    }

    .mobileHeader .custom-circle-progress {
        right: -40px;
        top: 0px;
    }

    .mobileHeader .shaped-btn-row .btn-shaped {
        height: 20px;
        flex: 0 0 65px;
        max-width: 65px;
        margin-bottom: 3px;
        font-size: 8px;
    }

    .coinIcon>div {
        width: 14px;
        height: 15px;
        font-size: 7px;
        right: 8px;
        top: 8px;

    }
}

@media only screen and (max-width: 767px) {
    .CookieContainer {
        justify-content: center;
        /* display: flex; */
        font-size: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .mobileHeader .mobile-centered-element {
        left: -20px;
    }

    .mobileHeader .navbar-brand {
        display: inline-block;
        text-align: right;
        margin-right: 0px;        
    }

    .mobileHeader .navbar-brand img {
        max-width: 50px;
        text-align: right;
    }

}

.form-inline-flex {
    display: flex;
    width: 100%;
    /* margin: 0px -10px !important; */
}

.form-inline-flex .col-field {
    flex: 0 0 42%;
    max-width: 42%;
    padding: 0px 10px;
}

.form-inline-flex .col-field>input,
.form-inline-flex .col-field>select {
    display: block;
    width: 100%;
}

.flex-auto {
    flex: auto;
}

.form-inline-flex select {
    color: #160133 !important;
    height: 44px;
}

.CookieConsent>div:first-child {
    flex: none !important;
}

@media only screen and (max-width: 767px) {
    .CookieConsent>div:first-child {
        flex: 0 0 100% !important;
        text-align: center;
        margin: 0px !important;
    }

    .CookieConsent>div button {
        margin: 3px !important;
    }
    .custom-swal-popup{
        border-radius: 100px 0px 100px 100px;
        width: 100%;
    }
    .custom-swal-popup h2{
        margin-top: 30px;
    }
}

.HomeImage {
    width: 100%;
    height: auto;
    /* position: absolute; */
}

@media screen and (min-width: 820px) and (max-width: 1180px) {
    .HomeImage {
        margin-top: 50px !important;
    }
}

.HomeText {
    z-index: 0;
    font-weight: "400";
    position: "relative";
}

/* @media screen and (min-width: 820px) and (max-width: 1180px) {
    .HomeText {
      margin-top: 182px;
    }
  } */

/* @media screen and (min-width: 1024px) and (max-width: 1366px) {
    .ipadProText {
      margin-top: unset;
    }
  } */


.voteDetails {
    position: relative;
    background-color: #ffffff;
    padding: 20px 26px;
    border-radius: 10px;
    cursor: pointer;
    max-width: 600px;
    width: 95%;
    margin: 0px auto;
    color: #160133;
}

.voteDetails .voteDetailRow {
    margin: 0px -8px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.voteDetails .voteDetailRow .voteCol {
    /* padding: 0px 8px; */
}

.voteDetails .voteDetailRow .voteAvtar {
    flex: 0 0 80px;
    max-width: 80px;
    border-radius: 9999px;
    height: 80px;
    overflow: hidden;
    /* box-shadow: rgb(250, 228, 129) 1px 0px 5px;
outline: 0.5px solid #D2CC2E; */
}

.voteDetails .voteDetailRow .voteAvtar img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    object-position: left bottom;
    border-radius: 0px !important;
}


.voteDetails .voteDetailRow .voteDtl {
    flex: 0 0 calc(100% - 80px);
    max-width: calc(100% - 80px);
}

.voteDetails .voteDtl .voteHead {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 15px;
    padding-left: 20px;
}
.LiveBannerHeard{
    min-height: 100vh;
    padding: 0px 0px 0px;    
}
.LiveBannerHeard .total-votes{
/* color: black; */
}
.LiveBannerHeard .target-votes{
/* color: black; */
}
.voteDetails.liveBanner {
    margin-top: 2% !important;    
    background-color: rgb(255, 255, 255) !important;
    display: flex !important;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    /* justify-content: center !important; */
}

.voteDtl .voteHead .displayName {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #160133;
    padding-right: 10px;
    border-right: 1px solid #160133;
    flex: 0 0 auto;
}

.voteDtl .voteHead .coinCompare {
    display: flex;
    align-items: center;
    padding-left: 0px;
    flex: auto;
}

.voteDtl .voteHead .coinCompare .coinSub {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;
    flex: 0 0 auto;
}

.coinCompare .coinSub .coinName {
    color: #160133;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
}

.coinCompare .coinSub .coinLogo {
    flex: 0 0 30px;
    max-width: 30px;
}

.coinCompare .coinSub .coinLogo img {
    max-width: 100% !important;
    width: 100% !important;
    height: auto !important;
}

.coinCompare .sp-line {
    flex: 0 0 60px;
    max-width: 60px;
    border-top: 1px dashed #160133;
    width: 60px;
    float: left;
    position: relative;
    top: 2px;
}

.VotePower h4 {
    color: #160133;
    font-size: 14px;
    font-weight: 400;
}

.VotePower h4 span {
    color: #160133;
    opacity: 0.5;
}

.voteDtl .coinDetailsPara h4 {
    color: #160133;
    font-size: 14px;
    font-weight: 400;
}

.voteDtl .coinDetailsPara h4 span {
    color: #160133;
    opacity: 0.5;
}

.voteDtl .coinDetailsPara {
    display: flex;
    align-items: center;
    gap: 15px;
    padding-left: 10px;
}


.coinDetailsPara .coinCompare {
    display: flex;
    align-items: center;
    padding-left: 0px;
    flex: 0 0 300px;
    max-width: 300px;
}

.coinDetailsPara .coinCompare .coinSub {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0px 10px;
    flex: 0 0 auto;
    float: left;
}

.voteDtl .voteHead h4 {
    color: #160133;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
}

.voteDtl .voteHead h4 span {
    color: #160133;
    opacity: 0.5;
}

.botContent {
    display: flex;
    align-items: center;
    gap: 20px;
    /* flex-wrap: wrap; */
}

.GameNameHeading{
    color: black;
    text-align: center;
    padding:20px 0px ;
}

@media only screen and (max-width: 767px) {
    .voteDtl .voteHead .displayName {
        font-size: 18px;
        line-height: 22px;
    }

    .voteDtl .coinDetailsPara h4 {
        font-size: 13px;
    }

    .coinCompare .coinSub .coinName {
        font-size: 13px;
    }

    .coinCompare .sp-line {
        flex: 0 0 30px;
        max-width: 30px;
    }

    .voteDtl .coinDetailsPara {
        gap: 10px;
    }

    .voteDtl .coinDetailsPara {
        flex-wrap: wrap;
    }

    .voteDetails .voteDetailRow .voteAvtar {
        margin-top: 15px;
    }
}

@media only screen and (max-width: 575px) {
    .voteDetails .voteDetailRow .voteAvtar {
        flex: 0 0 60px;
        max-width: 60px;
        height: 60px;
    }

    .voteDetails .voteDtl .voteHead {
        flex-wrap: nowrap;
        margin-bottom: 10px;
        justify-content: center;
    }

    .voteDetails .voteDtl .voteHead>div {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .voteDtl .voteHead .displayName {
        margin-bottom: 5px;
    }

    .voteDtl .voteHead .coinCompare {
        margin-left: -10px;
    }

    .coinCompare .sp-line {
        flex: 0 0 20px;
        max-width: 20px;
    }

    .voteDtl .voteHead .coinCompare .coinSub {
        gap: 6px;
        padding: 0px 10px;
    }

    .voteDetails .voteDetailRow {
        align-items: flex-start;
    }

    .voteDetails.liveBanner {
        margin-top: 2% !important;
        background-color: rgb(255, 255, 255) !important;
        display: flow-root !important;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
    }

    .voteDtl .voteHead .displayName {
        font-size: 14px;
        padding-right: 6px;
    }

    .voteDtl .voteHead h4 {
        font-size: 13px;
        padding-left: 6px;
        margin-bottom: 6px;
    }

    .voteDtl .coinDetailsPara {
        flex-wrap: wrap;
        justify-content: center;
        flex-direction: column;
    }

    .coinDetailsPara .coinCompare {
        flex: 0 0 100%;
        max-width: 100%;
        justify-content: center;
    }
}

@media only screen and (max-width: 484px) {
    .modal-content{
        width: 85%;
    }
}

@media only screen and (max-width: 380px) {
    .modal-content{
        width: 97%;
    }
}

.gift_animation-css {
    animation: new_animation 2s ease 0s infinite;
}

@keyframes new_animation {

    0%,
    100% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(2deg);
    }

    20%,
    40%,
    60% {
        transform: rotate(-4deg);
    }

    30%,
    50%,
    70% {
        transform: rotate(4deg);
    }

    80% {
        transform: rotate(-2deg);
    }

    90% {
        transform: rotate(2deg);
    }
}

.container-slider .slick-slider .slick-slide {
    pointer-events: none;
}

/* 
.album-card-grid>div {
    height: 100%;
  }
  
  .album-card-grid>div .card-containerSets {
    min-height: 242px !important;
  }
  
  .album-card-grid>div .card-containerSets .front{
    height: 100%;
    position: relative;
  }
  
  .album-card-grid>div .card-containerSets .front .album-card {
    height: 100% !important;
  }
  .album-card-grid>div .card-containerSets .album-card .d-full{
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .album-card-grid>div .card-containerSets .front .album-card .card-body img {
    object-fit: contain;
  }
  
  .album-card-grid>div .card-containerSets .front .album-card {
    height: 100%;
  }
  
  .album-card-grid .OpenBuyOffer {
    height: 32px !important;
  } */

/* Ensure that your custom styles are not overridden by browser defaults */
.loginFormInputField:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 0 white inset !important;
    box-shadow: 0 0 0px 1000px white inset !important;
}

.swal2-actions .swal2-confirm {
    margin-right: 10px;
    /* Adjust the value to control the gap size */
}

.notications_badge {
    align-items: center;
    /* position: fixed; */
    position: absolute;
    top:29px;
    left:50px;
    /* top: -18px; */
    border: 1px solid red;
    border-radius: 15px;
    /* right: 11px; */
    background: red;
    padding: 0 4px;
    display: flex;
    justify-content: center;
    width: 27px;
    height: 27px;
}

.notications_bade_menu {
    font-size: 9px;
    align-items: center;
    position: relative;
    top: -34px;
    border: 1px solid red;
    border-radius: 15px;
    background: red;
    padding: 4px 4px;
    display: flex;
    justify-content: center;
    width: 18px;
    height: 18px;
    left: 25%;
}

@media only screen and (max-width: 767px) {
 .notications_badge {
    position: absolute;
    left: 32px;
    top: 40px;
    color: #fff;
    z-index: 99999;
    background: #f30;
    border-radius: 50px;
    padding: 0 4px;
    width: 25px;
    height: 25px;
}
/* .notications_bade_menu {
    left: 25%;
    top: -34px;
  } */
}

/* Banner updated css starts */

.bannerMainDiv {
    height: calc(100vh - 116px);
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bannerMainDiv.bannerTwo {
    background-image: url("../public/linearH-banner-bg.jpg");
}

.bannerMainDiv.bannerTwo>img {
    height: 92%;
    object-fit: contain;
    object-position: center;
}

@media only screen and (min-width: 1025px) {
    .bannerMainDiv .mobileImage {
        display: none !important;
    }

    .bannerMainDiv {
        padding: 15px;
    }
}

@media only screen and (max-width: 1024px) {
    .bannerMainDiv {
        background-image: none !important;
        height: initial !important;
    }

    .bannerMainDiv .mobileImage {
        display: block !important;
    }

    .bannerMainDiv .desktopImage {
        display: none !important;
    }

    .bannerMainDiv.bannerTwo>img {
        height: auto !important;
    }
}

/* Banner updated css ends */

@keyframes bull_shake_modified {

    0%,
    100% {
        transform: rotate(0deg);
        transform-origin: 0%;
    }
}

.element {
    animation: bull_shake_modified 500ms infinite;
}


.coin_with_button {
    position: relative;
}

.coin_with_button .btn-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0px;
    right: 0px;
    margin: 0px auto !important;
    border-radius: 9999px;
    width: 70px;
    height: 70px;
    white-space: nowrap;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    color: #160133;
    font-size: 18px;
    font-weight: 600;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 20px;
    animation: pulse 1.5s infinite;
    border: 2px solid rgb(22 1 51 / 40%) !important;

    /* 3D Effect */
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3),
        inset -2px -2px 4px rgba(255, 255, 255, 0.5),
        inset 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.coin_with_button .btn-text:hover {
    /* Add a hover effect to make the 3D pop more */
    box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.4),
        inset -2px -2px 4px rgba(255, 255, 255, 0.6),
        inset 2px 2px 4px rgba(0, 0, 0, 0.15);
    transform: translateY(-50%) scale(1.05);
}


@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(84, 110, 164, 0.7);
    }

    70% {
        box-shadow: 0 0 0 30px rgba(84, 110, 164, 0);
    }

    100% {
        box-shadow: 0 0 0 0 rgba(84, 110, 164, 0);
    }
}

.gr_bg {
    background: linear-gradient(rgb(22, 1, 63) 0%, rgba(99, 82, 232, 0.73) 50%, rgb(19, 3, 49) 100%) !important;
}

.gr_bg .bg_dark_form .form-label {
    color: #ffffff;
    margin-bottom: 5px;
}

.bg-transperent {
    background: transparent !important;
}

.gr_bg .content-pages,
.gr_bg .content-pages * {
    color: #ffffff;
}

.gr_bg .content-pages .page-title {
    font-size: 26px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 30px;
    line-height:35px;
}

.gr_bg .content-pages p, .content-pages p {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 15px;
    font-weight: 400;
}

.gr_bg .content-pages .row p, .content-pages .row p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
    font-weight: 400;
}

.sub-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 15px;
}

.about-pic-bg {
    display: block;
    background: #000000;
    border-radius: 30px;
    overflow: hidden;
    max-width: 300px;
    border: 1px solid #5e5e5e;
    box-shadow: 0px 0px 3px 0px #bbbbbb;
    height: 355px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gr_bg .trade-buttonsdiv button, .gr_bg .nav.nav-tabs .nav-item .nav-link{
    color: #ffffff !important;
}

.gr_bg .nav.nav-tabs .nav-item .nav-link {
    border-left: 0px !important;
    border-top: 0px !important;
    border-right: 0px !important;
}

.font-sm {
    font-size: 12px !important;
}
.text-left {
    text-align: left !important;
}

/* Video container for responsiveness */
.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
}

.video-container video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ambassador_img {
    border: 2px solid #ffffff;
    overflow: hidden;
    max-width: 800px;
    margin: 40px auto;
    border-radius: 25px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
    background: #000000;
    padding: 0;
}
.ambassador_img img {
    width: 100%;
    vertical-align: top;
    object-fit: contain;
}

.bg-child-none>div, .bg-child-none>div>div>div {
    background-image: none !important;
}

.gr_bg .nav.nav-tabs .nav-item .nav-link:hover {
    border-bottom-color: transparent !important;
}

.gr_bg .nav.nav-tabs .nav-item .nav-link.active:hover {
    border-bottom-color: rgb(93, 73, 224) !important;
}

@media only screen and (max-width: 979px) { 
    
    .gr_bg .content-pages p {
        font-size: 14px;
        line-height: 24px;
    }
    
    .gr_bg .content-pages .row p {
        font-size: 12px;
        line-height: 22px;
    }

    
}

@media only screen and (max-width:500px) { 
        
    .about-pic-bg{
        height: 300px;
    }
}

@media only screen and (max-width:767px) {     
    .gr_bg .nav.nav-tabs .nav-item .nav-link.active:hover {
    border-bottom-color: rgb(255, 255, 255) !important;
}
}

.extra-vote-animation-corner {
    position: absolute;  
    top: -80px;
    right: -60px;
  }
  
  @media only screen and (max-width: 575px) {
    .extra-vote-animation-corner {
      top: -100px;
      right: -40px;
    }
  }




html, body {
    touch-action: manipulation;    
    user-select: none;
    overscroll-behavior: none; 
  }
