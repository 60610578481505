@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
.slick-dots li.slick-active button:before {
  color: #6352e8;
}

.slick-prev:before,
.slick-next:before {
  color: #6352e8;
}
.slick-prev:before,
.slick-next:before {
   display:none;
}


/* .slick-slider{
  display: flex; 
  justify-content: center;
} */
/* .slick-list{
  border:1px solid red;
  width :730px;
} */
.bg-Change{
  /* border:10px solid red; */
  /* width: 100%;
  height: 150vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000000; */
}

.tab-buttonsdiv {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.tab-buttonsdiv button {
  /* flex: 1; */
  font-size: 17px;
  font-weight: 400;
  padding: 0.6rem 1rem;
  cursor: pointer;
  border: none;
  color:rgb(143, 143, 143);
  background:none;
}

.tab-buttonsdiv button.active {
  /* background: ; */
border-bottom:2px solid #6352e9 ;
  color:black;
}
.tradesdiv{
  margin-top:-40px !important;
}

.tab-contentdiv{
  color: black;
}

.trade-buttonsdiv {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

.trade-buttonsdiv button {
  /* flex: 1; */
  font-size: 14px;
  font-weight: 300;
  padding: 0.6rem 1rem;
  cursor: pointer;
  border: none;
  color:rgb(143, 143, 143);
  background:none;
}
.viewListdataBig{
  min-height: 100px;
  /* overflow-x: scroll; */
  padding: 5px ;
}
.viewListdata{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin-bottom: 10px ;
}

.NegotiateListdata{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;  
}

.NegotiateListdataBig{  
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 10px;
  margin-bottom: 10px ;
  background:white;
}

.bottomTitle{  
  border-top:1px solid #dcdcdc;
  padding:8px;
  color: #198754;
}
.semiblodText{  
  font-weight: 600;

}
.trade-buttonsdiv button.active {
  /* background: ; */
border-bottom:2px solid #6352e9 ;
  color:black;
}

.trade-contentdiv{
  color: black;
}


.card-containerSets{ 
  max-width: 140px !important; 
  min-height: 210px !important;    
  position: relative;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 10px 10px;
}

.card-container{ 
  max-width: 250px !important; 
  min-height: 330px !important;    
  position: relative;
  transform-style: preserve-3d;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  margin: 10px 15px;
}
.flipped > .front {
      transform: rotateY(180deg);
                    }
.flipped > .back {
    transform: rotateY(0deg);
      }
.front{    
    display: block;        
    position: absolute;   
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
  }
.back {        
    display: block;        
    position: absolute;   
    backface-visibility: hidden;
    transform-style: preserve-3d;
    transition: -webkit-transform ease 500ms;
    transition: transform ease 500ms;
  }
  .front {
    width: 100%;
      z-index: 2;
      transform: rotateY(0deg); 
      margin: 0px 10px;  
      border-radius:0px 0px 10px 10px;     
      box-shadow: 0px 5px 10px 5px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1),0px 10px 15px -3px rgba(0,0,0,0.1) !important;
    }
.back {
  width: 100%;
    transform: rotateY(-180deg);   
    font-size: 20px;
    border-radius:0px 10px 10px 10px;     
}
.card-body{
  width: 100%;
  display: flex;
  justify-content: center;
}
.color-back{
  border: 1px solid #543cd6;
  background-color: #2f1975;
  color: white;
  padding: 0px 10px;

}
.color-back::placeholder{
  color: white; 
}
.color-back:focus{
  outline: none;
}


@media screen and (max-width: 450px) {
  .card-container{ 
    max-width: 310px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
.votingPaymentContainer .containerVote{
  width: 80%;
}
}
@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) {
  .upgrade_items_row .upgrade_items {flex: 0 0 25%;max-width: 25%;height: auto !important;}
.upgrade_items .stars_items img{max-width: 35px;}
.upgrade_items .btn_buy_now {width: 175px !important;}
.upgrade_items .btn_buy_now button {width: 160px !important;}
.bg_responisve_voting {background-size: cover !important;min-height: 80vh;}
.bg_responisve_voting .bottomImageBlock {
  bottom: -30% !important;
  top: initial !important;
}
.bg_responisve_voting .padBotImgBox {
  padding-bottom: 30% !important;
}
}

@media only screen and (min-device-width: 767px) and (max-device-width: 1024px) {
  .upgrade_content {padding: 0px 15px;}
.upgrade_content .upgrade_center_side>img{margin: 0px auto !important;max-width: 100%;}
.upgrade_content .upgrade_side {width: 40% !important;}
.upgrade_content .upgrade_center_side {width: 20% !important;}
.upgrade_content .innerContent p, .upgrade_content .innerContent span {padding: 5px;font-size: 15px;}
.upgrade_content .innerBlock {padding: 2px 5px;}
.upgrade_content .innerBlock div>img {max-width: 100%;}
.additionBox {max-width: 90%;}
.galleryRow {padding: 0px 10px;}
.galleryRow .galleryCol {margin: 0px 5px;flex: 0 0 calc(50% - 10px);max-width: calc(50% - 10px);width: calc(50% - 10px) !important;margin-bottom: 10px;}
.diff_item_Col {width: calc(50% - 10px) !important;margin-left: 5px !important;margin-right: 5px !important;}
.ipad_row_gap {row-gap: 10px;}
}
@media only screen and (max-width: 767px) {
  .galleryRow {padding: 0px 10px;}
  .galleryRow .galleryCol {margin: 0px 5px;flex: 0 0 calc(50% - 10px);max-width: calc(50% - 10px);width: calc(50% - 10px) !important;margin-bottom: 10px;}
  .diff_item_Col {width: calc(50% - 10px) !important;margin-left: 5px !important;margin-right: 5px !important;margin-top: 0px !important;}
}

@media only screen and (max-width: 640px) {
  .galleryRow .galleryCol {flex: 0 0 100%;max-width: 380px;width: 100% !important;margin: 0px auto;margin-bottom: 10px;}
  .diff_item_Col {width: 100% !important;margin-left: 5px !important;margin-right: 5px !important;max-width: 380px;}
}


.container-slider {
width: 100% !important;
max-width: 100% !important;
margin: -40px 0px 0px 0px !important;
}

.FireActive{
  font-family: "Staatliches", sans-serif;
  color: #ff6a00;
  font-size: 3rem;
  text-align: center;  
  transition: all 0.3s ease-in-out !important;  
  animation: FireActive 0.3s infinite alternate ease-in-out !important;  
  letter-spacing: 5px;
  text-shadow: 0 0 15px rgba(255, 165, 0, 1), 0 0 25px rgba(255, 69, 0, 0.9);
  /* filter: drop-shadow(0 0 10px #ff6600) drop-shadow(0 0 20px #ff3300); */
}
@keyframes FireActive {
  0%{
    opacity: 1;
  }
 100%{
    opacity: 0.7;
  }
}


.ZoonActive {
  font-family: "Staatliches", sans-serif;
  font-size: 3rem;
  text-align: center;  
  text-shadow: 0 0 20px #6352e8, 0 0 25px #6b5be6;  
  letter-spacing: 5px;
  transition: all 0.3s ease-in-out !important;  
  animation: ZoonActive 0.3s infinite alternate ease-in-out !important;  
  /* background: transparent; */
  /* -webkit-text-stroke: 1px #6352e8; */
}

@keyframes ZoonActive {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.4;
  }
}
