.card-content{    
    /* Width:100%; */
    height: 460px;
}
.card-contentMob{    
    Width:100% !important;
    height: 460px;
}
.PaxText{
animation: blinker 1s linear infinite;
}
@keyframes blinker {
  50% {
    opacity: 0;
  }
}
.popupStyle {
  border-radius:100px 0px 100px 100px
}

.modulebackground{
  background-color:transparent !important;
  border: none !important;
}

.modal-content.ForBigDiv{
  margin: 0px !important;
  padding: 0px !important;
  /* background-image:url("../../assets/images/popupline.png") , linear-gradient(180deg, #543CD6 0%, #361F86 48.96%, #160133 100%); */
  background-image:url("../../assets/images/popupbg2.png");
  /* background-image:url("../../assets/images/popupline.png"), url("../../assets/images/popupbg.png"); */
  /* background: linear-gradient(180deg, #543CD6 0%, #361F86 48.96%, #160133 100%); */
/* box-shadow: 1px 1px 30px 0px #8a76fb; */
  /* background-position: cover; */
    /* background-attachment: fixed; */
  background-position: center;
  border-radius:0px 0px 0px 0px !important;
  width: 100% !important;
}
.modal-content.ForBigNft{
  margin: 0px !important;
  padding: 0px !important;
  /* background-image:url("../../assets/images/popupline.png"), url("../../assets/images/popupbg.png"); */
  /* background-position: cover; */
    background-attachment: fixed;
  background-position: center; 
  border-radius:0px 0px 0px 0px !important;
  width: 100% !important;
}
.legendaryBG{
  background:url("../../assets/images/linesBG.png"),linear-gradient(180deg, #FFE224 0%, #FFC92B 48.96%, #FFB434 100%);
  /* background-image:url("../../assets/images/linesBG.png"), url("../../assets/images/legendaryBG.png"); */
    background-position: center,center;
}
.rareBG{
  background:url("../../assets/images/rarelineBG.png"),linear-gradient(180deg, #F8F8F8 0%, #EEE 48.96%, #C1C1C1 100%);;
  /* background-image:url("../../assets/images/rarelineBG.png"), url("../../assets/images/rareBG.png"); */
    background-position: center,center;
}
.uncommonBG{
  background:url("../../assets/images/linesBG.png"), linear-gradient(180deg, #C6ABFF 0%, #E1D2FF 100%);
  /* background-image:url("../../assets/images/linesBG.png"), url("../../assets/images/uncommonBG.png"); */
    background-position: center,center;
}
.epicBG{
  /* background-image:url("../../assets/images/linesBG.png"), url("../../assets/images/epicBG.png"); */
  background:url("../../assets/images/linesBG.png"),linear-gradient(180deg, #B7AFF5 0%, #6355D0 100%);
    background-position: center,center;
}
.commonBG{
  background:url("../../assets/images/linesBG.png"),linear-gradient(180deg, #C5B9FF 0%, #EDEAFB 100%);
  /* background-image:url("../../assets/images/linesBG.png"), url("../../assets/images/commonBG.png"); */
    background-position: center,center;
}
.sameBG{
  background:url("../../assets/images/popupbg2.png");
  /* background-image:url("../../assets/images/linesBG.png"), url("../../assets/images/commonBG.png"); */
    background-position: center,center;
}

.btn-close{
  color: white !important;
}
.newtooltip{
  background: var(--color-160133) 0 0% no-repeat padding-box;
    border: 2px solid var(--color-6352e8);
    box-shadow: 0 3px 6px #00000029;
    border-radius: 0 20px 20px 20px;
    opacity: 1;
    font: var(--font-style-normal) normal var(--font-weight-normal) var(--font-size-12)/var(--line-spacing-13) var(--font-family-poppins);
    letter-spacing: var(--character-spacing-0);
    color: var(--color-ffffff);
    text-align: center;
    /* width: 300px; */
    padding: 15px !important;  
    position: absolute;
    z-index: 10;
}

.Homeimg {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.AvatarModal{
  background: none !important;
  border: none !important;
  width: 100% !important;
  padding: 0px !important;
  margin: 15% 0px 0px 0px !important;
}

.AvatarModalTop{
  margin: 40% 0px 0px 0px !important;
}
.loginPopup{
  margin: 45% auto !important; 
  width: 90% !important; 
}

.loginPopupTop{
  margin: 40% auto !important;  
  width: 80% !important;
}
/* .coinText{  
text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.50); 
font-family: Poppins;
font-size: 58px;
font-style: normal;
font-weight: 700;
line-height: normal;
text-transform: capitalize;
color:#dedada;
font-size: 20px !important;
} */


.wm-center-div{
  margin-top: 10% !important;
}

@media (max-width: 992px){
.wm-center-div{
  margin-top: 10% !important;
}
}
/* @media (max-width: 768px){
.wm-center-div{
  margin-top: 12% !important;
}
} */
@media (max-width: 556px){
.wm-center-div{
  margin-top: 35% !important;
}

}
.wm-modal [type="button"] {
  cursor: pointer;
  background-color: black;
  transition: background-color .2s ease-in-out 0s,color .2s ease-in-out 0s;
  min-height:6em !important;
}
/* .selectDropdown{
   padding: .375rem 2.25rem 0.375rem 0.75rem !important;    
    color: #ffffff !important;
    background-color: transparent !important;
    background-position: right 0.75rem center !important;  
} */
.opction{
    color: red !important;;
  }
/* .customOption{
  background-color: red !important;
} */

/* .newtooltip .mytooltip::before {
    top: 7px;
    left: -21px;
    border-width: 0;
    content: "hello" !important;
    color: var(--color-6352e8);
    position: absolute;
} */
/* .newtooltip::before{
content: "" !important;
} */
/* #d4d0f3 */
/* #6352e8 */

/* .coin_Bg{
 background-image: url(../../assets/images/coin_bg.png);
} */


.pay-custom-select-container {
  display: inline-block;
  width: 100%;  
  /* text-align: center; */
  
  position: relative;
}

.pay-selected-textv2 {
  /* background-color: #47eded; */
  background-color: transparent;
  border: 1px solid #cab7ff;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 8px 8px 8px 8px;
  
  /* border-bottom: 1px solid #37b0b0; */
}
.pay-selected-textv2.active::after {
  top: 8px;  
  border-color: transparent transparent #fff transparent;
}
.pay-selected-textv2.active {
  border-radius: 8px 8px 0px 0px;
  border-bottom:none ;
}

.pay-selected-text {
  /* background-color: #47eded; */
  background-color: transparent;
  border: 1px solid #cab7ff;
  padding: 10px 20px;
  font-size: 15px;
  border-radius: 8px 8px 8px 8px;
  
  /* border-bottom: 1px solid #37b0b0; */
}

.pay-selected-text::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 20px;
  border: 7px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.pay-selected-text.active::after {
  top: 8px;  
  border-color: transparent transparent #fff transparent;
}
.pay-selected-text.active {
  border-radius: 8px 8px 0px 0px;
  border-bottom:none ;
}


.pay-custom-select-container ul {
  /* border: 1px solid #cab7ff; */
  /* border-radius: 0px 0px 8px 8px ;
  border-top: none; */
  margin: 0;
  padding: 0 0px;
  text-align: center;
}

.pay-select-options {
  position: absolute;
  width: 100%;    
  overflow: scroll
}

.pay-custom-select-container ul li {
  list-style-type: none;
  padding: 10px 20px;
  /* background: transparent; */
  background: #1e0243;
  border-top: 1px solid #cab7ff;  
  text-align: left;
  font-size: 15px;
  cursor: pointer;
}


.radio-trade > input:checked{
  background-color: #6357e5 !important;
  border: #6357e5 !important;
}
.SetInputSize{
  width: 80%;
  margin-top: 15px;
  height: 35px;
}
.SetInputSize::placeholder{
  color:rgb(55, 55, 55);
  font-size: 15px;
}
.Description{
  margin-left: 30px;
  margin-top: 10px;
}
.Description > strong{
  font-size: 11px;
}
.Description > p{
  font-size: 10px;
}

/* .pay-custom-select-container ul li:hover {
 
} */


/* .boxCheck:focus{
box-shadow:none !important;
} */

.backGroundRotate {
    position: relative; /* Ensure the container is positioned relative */
    width: 300px; /* Set your desired width */
    height: 300px; /* Set your desired height */
    overflow: hidden; /* Hide overflow to manage rotated image */
}

.backGroundRotate::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 150%; /* Increase size to avoid cutting off corners */
    height: 150%; /* Increase size to avoid cutting off corners */   
    transform: translate(-50%, -50%) rotate(45deg); /* Adjust rotate value as needed */
}

.boxCheck:focus ~ .custom-control-label::before {
  box-shadow:none !important;
}

.rewardCliam.animate:before {
    display: block;
    animation: topBubbles ease-in-out 0.75s forwards;
}

.rewardCliam.animate:after {
    display: block;
    animation: bottomBubbles ease-in-out 0.75s forwards;
}

@keyframes topBubbles {
    0% {
        background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
    }
    50% {
        background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
    }
    100% {
        background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}

@keyframes bottomBubbles {
    0% {
        background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
    }
    50% {
        background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
    }
    100% {
        background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
        background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
    }
}


.rewadTextfade {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.8s ease, visibility 0.8s ease;
}

.rewadTextfade.visible {
  opacity: 1;
  visibility: visible;
  margin: 10px 0px;
}

.rewadTextfade.hidden {
  opacity: 0;
  visibility: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  /* gap: 10px; */
}