.container-fluid {
  height: auto !important;
 }
 
 /* Counter Spinner CSS */
 .counter-wrapper {min-height: 80vh;display: flex;align-items: center;justify-content: center;background: linear-gradient(rgb(22, 1, 63) 0%, rgba(99, 82, 232, 0.73) 50%, rgb(19, 3, 49) 100%);flex-direction: column;}
 .counter-wrapper .counter-container {flex: 0 0 100%;width: 100%;max-width: 100%;padding: 0px 6px;}
 .counter-container h1 {font-size: 36px;font-weight: 600;}
 .counter-container h2 {font-size: 14px;font-weight: 400;}
 .counter-row {margin: 30px -6px 0px;display: flex;align-items: center;justify-content: center;}
 .counter-row .ct-box {padding: 0px 6px;flex: 0 0 120px;max-width: 120px;}
 .counter-row .ct-box .ct-inner {height: 100px;width: 100%;border: 6px solid #cbc0b4;border-image: url('../../Pages/streakBonus/img/uncommon.png') 30 stretch;border-radius: 0px;box-shadow: 2px 5px 11px 0px #cfadad;background: #6052c9;position: relative;}
 .counter-row .ct-box .radius {border-radius: 10px;overflow: hidden;}
 .counter-row .ct-box .ct-inner.active {border-color: #efbd8a;border-image: url('../../Pages/streakBonus/img/legendary.png') 160 stretch;}
 .counter-row .ct-box .ct-inner .text-end-spin {padding: 4px 8px;background: #8071ef;font-size: 14px;color: #d1d1d1;font-weight: 600; text-align: center;}
 .counter-row .ct-box .ct-inner.active .text-end-spin {background-image: url('../../Pages/streakBonus/img/legendary.png');background-repeat: no-repeat;background-position: left;color: #634848;}
 .counter-row .ct-box .ct-inner .ct-text {font-size: 22px;font-weight: 700;padding: 4px 2px;position: relative;top: 8px;white-space: nowrap;}
 .counter-row-section {width: 100%;display: flex;align-items: center;justify-content: center;}
 .counter-row-section>.text-center {flex: 0 0 700px;max-width: 700px;}
 .counter-result-section {position: relative;height: 200px;width: 200px;flex: 0 0 200px;max-width: 200px;border-radius: 9999px;display: flex;align-items: center;justify-content: center;background: #ab98eb url("../../Pages/streakBonus/img/popupline-t.png");background-repeat: no-repeat;background-size: cover;font-family: fantasy;font-size: 60px;text-shadow: 2px 1px 1px #040303;flex-direction: column;line-height: normal;}
 .counter-result-section h3 {top: 0px;text-transform: uppercase;font-size: 18px;left: 0px;right: 0px;text-align: center;font-family: 'Poppins';text-shadow: 1px 1px 1px #040303;}
 
 
 /* Wheel Spinner CSS */
 .wheel-spin-box-reward {position: relative;display: inline-block;}
 #spinwheel {position: relative;width: 360px;margin: auto;}
 .goldSpinWheel-reward .wheeldotsround {
   position: absolute;
   width: 100%;
   height: 100%;
   left:auto;
   top:auto;
 }
 
 .rotated-div {
   position: absolute;
   left: 128px;
   top:137px;  
 }
 .wheeldotsround .rotated-text{
   position: absolute;
   z-index: 1;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(1) {
   transform: rotate(23deg) translateX(140px) rotate(88deg);
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(2) {
   transform: rotate(65deg) translateX(140px) rotate(88deg);
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(3) {
   transform: rotate(113deg) translateX(140px) rotate(88deg);
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(4) {
   transform: rotate(158deg) translateX(140px) rotate(88deg);
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(5) {
   transform: rotate(203deg) translateX(140px) rotate(88deg);
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(6) {
   transform: rotate(247deg) translateX(140px) rotate(88deg);
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(7) {
   transform: rotate(293deg) translateX(140px) rotate(88deg);
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(8) {
   transform: rotate(335deg) translateX(140px) rotate(88deg);
 }
 .wheeldotsrounddots {position: absolute;width: calc(100% + 43px);height: calc(100% + 42px);left: -22px;top: -20px;}
 .wheeldotsrounddots .wheeldots {width: 10px;height: 10px;border-radius: 50%;background: #fff;position: absolute;z-index: 1;filter: blur(1.1px);}
 .wheeldotsrounddots .wheeldots:nth-child(2n+1) {background: #fff;}
 .wheeldotsrounddots .wheeldots.active-dots {background: #ffaa1c;}
 .wheeldotsrounddots .wheeldots.active-dots:nth-child(2n+1) {background: #2660a4;}
 .wheeldotsrounddots .wheeldots:nth-child(1) {left: calc(50% - 4px);top: 3px;display: none;}
 .wheeldotsrounddots .wheeldots:nth-child(2) {right: 25%;top: 7%;}
 .wheeldotsrounddots .wheeldots:nth-child(3) {right: 7%;top: 25%;}
 .wheeldotsrounddots .wheeldots:nth-child(4) {right: 3px;top: 50%;}
 .wheeldotsrounddots .wheeldots:nth-child(5) {right: 8.2%;bottom: 23%;}
 .wheeldotsrounddots .wheeldots:nth-child(6) {right: 25%;bottom: 7%;}
 .wheeldotsrounddots .wheeldots:nth-child(7) {left: calc(50% - 4px);bottom: 3px;}
 .wheeldotsrounddots .wheeldots:nth-child(8) {left: 25%;bottom: 7%;}
 .wheeldotsrounddots .wheeldots:nth-child(9) {left: 8.2%;bottom: 23%;}
 .wheeldotsrounddots .wheeldots:nth-child(10) {left: 3px;top: 50%;}
 .wheeldotsrounddots .wheeldots:nth-child(11) {left: 7%;top: 25%;}
 .wheeldotsrounddots .wheeldots:nth-child(12) {left: 25%;top: 7%;}
 
 #spinwheel {position: relative;width: 300px;margin: auto;z-index: 4;}
 .goldSpinWheel-reward #spinwheel svg {
   width: 100%;
   height: 100%;
   border-radius: 50%;
   background: #f57f25;
   /* background: rgb(22, 1, 51); */
   /* background: linear-gradient(200deg, rgb(81 36 144) 0%, rgb(100 75 134) 40%, rgb(145 119 181) 100%); */
   /* background-image: url("../../Pages/streakBonus/img/newlegendary-a.png"); */
   background-color: white;
   padding: 20px;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center; 
 }
 .wheel-spin-box-reward {position: relative;border: 14px solid #fda73a;border-radius: 9999px;padding: 10px;}
 .wheel-spin-box-reward .spin-bot {position: absolute;width: 347px;bottom: -110px;left: -12px;z-index: 2;}
 .leftWing {position: absolute;left: -14px;top: -70px;width: 100px;}
 .rightWing {position: absolute;right: -14px;top: -70px;width: 100px;}
 .wheel-spin-box-reward::before {
   position: absolute;
   content: "";
   border: 6px solid #f5b02c;
   width: calc(100% + 46px);height: calc(100% + 46px);
   z-index: 3;
   left: -23px;
   right: 0px;
   border-radius: 9999px;
   margin: 0px auto;
   top: -23px;
   background-color: #e57a0f;}
 .container-spin-wheel {width: 100%;text-align: center;padding: 100px 50px 20px 50px!important;margin-top: 0px !important;}
 .wheel-spin-box-reward::after {position: absolute;content: "";border: 3px solid #fda73a;width: calc(100% - 5px);height: calc(100% - 5px);z-index: 1;left: 0px;right: 0px;border-radius: 9999px;margin: 0px auto;top: 3px;}
 .wheel-spin-box-reward .spin-click-button {position: absolute;top: 50%;transform: translateY(-50%);z-index: 2;border-radius: 9999px;width: 65px;height: 65px;white-space: nowrap;background: #ffffff;
  /* background-image: url("../../Pages/streakBonus/img/newlegendary-a.png") */
  ;background-repeat: no-repeat;background-position: center;background-size: cover;color: #f57f25;font-size: 14px;padding: 0px;display: flex;align-items: center;justify-content: center;margin: 0 auto;left: 0px;right: 0px;box-shadow: 0px 0px 57px 2px #3f3737;z-index: 6;transition: transform 0.3s ease;}
 .wheel-spin-box-reward .spin-click-button:after {position: absolute;content: "";border: 3px solid #fda73a;width: calc(100% - 5px);height: calc(100% - 5px);z-index: 1;left: 0px;right: 0px;border-radius: 9999px;margin: 0px auto;top: 3px;}
 .modal-content #card-animation {pointer-events: none;}
 .vote-result-model .modal-body, .vote-result-model .modal-footer {z-index: 2;}
 
 /* .wheel-spin-box-reward .spin-click-button:hover */
 .wheel-spin-box-reward .spinround
 /* , .wheel-spin-box-reward .spin-click-button:focus  */
 {
 background-size: 600% 620%;
 transform: translateY(-50%) scale(1.1) rotate(360deg);
 font-size: 16px;
 }
 
 .wheel-spin-box-reward .spin-click-button:active {box-shadow: 0px 6px 45px 10px #c2b4ff;}
 
 .wheel-spin-box-reward .chartholder .slice path {fill: #ffaa1c;}
 .wheel-spin-box-reward .chartholder .slice:nth-child(2n+1) path {fill: #f57f25;}
 .wheel-spin-box-reward .chartholder .slice:nth-child(2n+1) path {fill: #f57f25;} 
/* .wheel-spin-box-reward .chartholder .slice:nth-child(2n+1) path {fill: #160133;} */

.wheel-spin-box-reward .chartholder .slice:last-child path {
  fill: #880000
  /* fill:url("#backgroundImageset"); */
}
.wheel-spin-box-reward .chartholder .slice:last-child text {
  font-weight: bold;
  fill: white;
}

 .mega-wheel-spinner .wheel-spin-arrow {position: absolute;text-align: center;left: 0px;right: 0px;margin: 0px auto;top: -20px;z-index: 5;}
 .mega-wheel-spinner .wheel-spin-arrow svg {max-width: 65px;stroke: #fff4eb;position: relative;top: 1px;width: 32px;height: 60px;left: 0px;stroke-width: 2px;}
 .spin-click-button 
 {
   background-color: #000;
   font-size: 14px;
   font-weight: 600;
   color: #fff;border: none;padding: 14px 35px;border-radius: 15px;}
 
 /* .wheel-spin-box-reward:hover {animation: sizeIncrease 0.7s ease-in-out forwards;} */
 
 
 .mid-text123{
   font-size: 35px;
 }
 /* Alternative way with keyframes */
 @keyframes sizeIncrease {
   from {
     transform: scale(1);
   }
 
   to {
     transform: scale(1.1);
   }
 }
 
 .counter-row .ct-box .ct-inner.active::before, .counter-row .ct-box .ct-inner.active::after {content: '';position: absolute;top: -5px;left: -5px;width: calc(100% + 10px);height: calc(100% + 10px);border: 5px dotted #fff;box-sizing: border-box;animation: lightUp 2s linear infinite;}
 .counter-row .ct-box .ct-inner.active::after {animation-delay: 1s;}
 
 @keyframes lightUp {
   0%, 100% {
       border-color: #ffaa1c;
   }
   50% {
       border-color: #ffffff;
   }
 }
 
 .coin-img {
   /* position: absolute;
   width: 450px;
   left: -110px;
   bottom: -150px;
   z-index: 1;
   pointer-events: none; */
   position: absolute;
   width: 500px;
   left: -70px;
   bottom: -150px;
   pointer-events: none;
   right: 0;
   margin: 0px auto;
   opacity: 0.8;
   z-index: 1;
 }
 
 .vote-result-model {z-index: 9 !important;color: #ffffff;}
 .vote-result-model * {color: #ffffff;}
 .vote-result-model .modal-content {border-radius: 0px !important;background-image: url(../../Pages/streakBonus/img/popupbg2.png);border: 4px solid #7f73d5;background-repeat: no-repeat;background-position: center;min-height: 350px;justify-content: center;gap: 20px; margin-top: -150px;}
 .curved-text {font-size: 44px;font-weight: 300;margin-bottom: 20px;color: #ffffff;fill: #ffffff;}
 .vote-result-model .btn-close {background-color: #ffffff;border-radius: 9999px;width: 30px;height: 30px;float: right;margin-left: auto;position: absolute;top: 10px;right: 10px;z-index: 1;}
 .vote-result-model .modal-body {flex: initial;padding: 0px;}
 .vote-result-model .modal-footer {border: 0px;}
 .vote-result-model .modal-footer .btn {background: #fda73a;border-radius: 9999px;font-size: 14px;padding: 10px 20px;color: #ffffff;font-weight: 500;}
 .vote-result-model .modal-body h4{font-size: 34px;font-weight: 300;margin-bottom: 20px;}
 .vote-result-model .modal-body p{font-size: 20px;font-weight: 400;margin-bottom: 10px;}
 .vote-result-model .modal-body .icon {margin-bottom: 20px;}
 .vote-result-model .modal-body .icon svg {height: 100px;fill: #fdef9c;width: 80px;}
 .vote-result-model .modal-body .icon img {width: 175px;max-width: 100%;margin: 0px auto;}
   @media (max-width: 640px) {
     .counter-row-section {display: block;}
     .counter-row-section .counter-row {flex-wrap: wrap;
     width: 100%;
     }
     .counter-row .ct-box {margin-bottom: 12px;}  
     .goldSpinWheel-reward .wheeldotsround .rotated-div{
    position: absolute;  
    left: 132px !important;
   top:140px !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(1) {
   transform: rotate(23deg) translateX(140px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(2) {
   transform: rotate(65deg) translateX(140px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(3) {
   transform: rotate(113deg) translateX(140px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(4) {
   transform: rotate(158deg) translateX(140px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(5) {
   transform: rotate(203deg) translateX(140px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(6) {
   transform: rotate(247deg) translateX(140px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(7) {
   transform: rotate(293deg) translateX(140px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(8) {
   transform: rotate(335deg) translateX(140px) rotate(88deg) !important;
 }
   }
 
   @media (max-width: 479.98px) {
   #spinwheel {width: 270px;}
 
 .goldSpinWheel-reward .wheeldotsround .rotated-div{
    position: absolute;  
    left: 114px !important;
   top:122px !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(1) {
   transform: rotate(23deg) translateX(124px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(2) {
   transform: rotate(65deg) translateX(124px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(3) {
   transform: rotate(113deg) translateX(124px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(4) {
   transform: rotate(158deg) translateX(124px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(5) {
   transform: rotate(203deg) translateX(124px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(6) {
   transform: rotate(247deg) translateX(124px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(7) {
   transform: rotate(293deg) translateX(124px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(8) {
   transform: rotate(335deg) translateX(124px) rotate(88deg) !important;
 }
 }
 
   @media (max-width: 400px) {
     .wheel-spin-box-reward #spinwheel {width: 220px;}
     .goldSpinWheel-reward .wheeldotsround .rotated-div{
    position: absolute;  
    left: 89px !important;
   top:99px !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(1) {
   transform: rotate(23deg) translateX(100px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(2) {
   transform: rotate(65deg) translateX(100px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(3) {
   transform: rotate(113deg) translateX(100px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(4) {
   transform: rotate(158deg) translateX(100px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(5) {
   transform: rotate(203deg) translateX(100px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(6) {
   transform: rotate(247deg) translateX(100px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(7) {
   transform: rotate(293deg) translateX(100px) rotate(88deg) !important;
 }
 
 .goldSpinWheel-reward .wheeldotsround .rotated-text:nth-child(8) {
   transform: rotate(335deg) translateX(100px) rotate(88deg) !important;
 }
 }
 
 .timeText{
   font-family: "Lilita One";
   text-shadow: "1px 0 #000, -1px 0 #000, 0 1px #000, 0 -1px #000, 1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000";
   word-spacing: "2px";
 }
