
/* .extra-vote-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #333;
    font-family: Arial, sans-serif;
    overflow: hidden;
} */
.container-extra-vote {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Sun element wrapper - this zooms in and out */
.sun-wrapper {
    position: relative;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: zoomWithPause 6s ease-in-out infinite;
}

/* Rays container - faster rotation speed */
.sun-wrapper .rays {
    position: absolute;
    width: 140px;
    height: 140px;
    animation: rotateCounterClockwise 8s linear infinite;
}

/* Enhanced rays with shadow effect */
.sun-wrapper .rays .ray {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 1px;
    background: linear-gradient(to right, 
                transparent, 
                rgba(255, 215, 0, 0.4) 10%, 
                rgba(255, 215, 0, 0.9) 50%,
                rgba(255, 215, 0, 0.4) 90%, 
                transparent);
    transform-origin: left center;
    box-shadow: 0 0 0px rgba(255, 215, 0, 0.8),
                0 0 0px rgba(255, 215, 0, 0.5);
}

/* 72 rays positioned every 5 degrees with slight length variation for natural look */
.sun-wrapper .rays .ray:nth-child(3n+1) { width: 45%; }
.sun-wrapper .rays .ray:nth-child(3n+2) { width: 43%; }
.sun-wrapper .rays .ray:nth-child(3n+3) { width: 47%; }

.sun-wrapper .rays .ray:nth-child(1) { transform: rotate(0deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(2) { transform: rotate(5deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(3) { transform: rotate(10deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(4) { transform: rotate(15deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(5) { transform: rotate(20deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(6) { transform: rotate(25deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(7) { transform: rotate(30deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(8) { transform: rotate(35deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(9) { transform: rotate(40deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(10) { transform: rotate(45deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(11) { transform: rotate(50deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(12) { transform: rotate(55deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(13) { transform: rotate(60deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(14) { transform: rotate(65deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(15) { transform: rotate(70deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(16) { transform: rotate(75deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(17) { transform: rotate(80deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(18) { transform: rotate(85deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(19) { transform: rotate(90deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(20) { transform: rotate(95deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(21) { transform: rotate(100deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(22) { transform: rotate(105deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(23) { transform: rotate(110deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(24) { transform: rotate(115deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(25) { transform: rotate(120deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(26) { transform: rotate(125deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(27) { transform: rotate(130deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(28) { transform: rotate(135deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(29) { transform: rotate(140deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(30) { transform: rotate(145deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(31) { transform: rotate(150deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(32) { transform: rotate(155deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(33) { transform: rotate(160deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(34) { transform: rotate(165deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(35) { transform: rotate(170deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(36) { transform: rotate(175deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(37) { transform: rotate(180deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(38) { transform: rotate(185deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(39) { transform: rotate(190deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(40) { transform: rotate(195deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(41) { transform: rotate(200deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(42) { transform: rotate(205deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(43) { transform: rotate(210deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(44) { transform: rotate(215deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(45) { transform: rotate(220deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(46) { transform: rotate(225deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(47) { transform: rotate(230deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(48) { transform: rotate(235deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(49) { transform: rotate(240deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(50) { transform: rotate(245deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(51) { transform: rotate(250deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(52) { transform: rotate(255deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(53) { transform: rotate(260deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(54) { transform: rotate(265deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(55) { transform: rotate(270deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(56) { transform: rotate(275deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(57) { transform: rotate(280deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(58) { transform: rotate(285deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(59) { transform: rotate(290deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(60) { transform: rotate(295deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(61) { transform: rotate(300deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(62) { transform: rotate(305deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(63) { transform: rotate(310deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(64) { transform: rotate(315deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(65) { transform: rotate(320deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(66) { transform: rotate(325deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(67) { transform: rotate(330deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(68) { transform: rotate(335deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(69) { transform: rotate(340deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(70) { transform: rotate(345deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(71) { transform: rotate(350deg) translateY(-50%); }
.sun-wrapper .rays .ray:nth-child(72) { transform: rotate(355deg) translateY(-50%); }

/* Circle element with enhanced glow */
.sun-wrapper .circle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none !important;
    z-index: 2;
    position: relative;
    animation: pulseGlow 3s ease-in-out infinite;
}

.sun-wrapper .circle img {
    width: 100px;
    height: 100px;
}

/* x17 text */
.sun-wrapper .text {
    margin-top: 10px;
    line-height: 12px;
    position: absolute;
    color: white;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.8),
                 0 0 20px rgba(255, 215, 0, 0.6);
    z-index: 3;
    font-size: 20px;
    font-weight: 400;
    animation: textGrowth 6s ease-in-out infinite;
}

/* Counter-clockwise rotation animation - faster */
@keyframes rotateCounterClockwise {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(-360deg);
    }
}

/* Zoom with pause animation - faster zoom in */
@keyframes zoomWithPause {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    /* Faster zoom in (only 10% of animation time) */
    10% {
        transform: scale(1);
        opacity: 1;
    }
    /* Stay visible from 10% to 70% of the animation time */
    70% {
        transform: scale(1);
        opacity: 1;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}

/* Circle glow pulsing effect */
@keyframes pulseGlow {
    0% {
        box-shadow: 0 0 20px rgba(255, 215, 0, 0.7), 
                   0 0 40px rgba(255, 215, 0, 0.5),
                   0 0 60px rgba(255, 215, 0, 0.3);
    }
    50% {
        box-shadow: 0 0 25px rgba(255, 215, 0, 0.9), 
                   0 0 50px rgba(255, 215, 0, 0.7),
                   0 0 75px rgba(255, 215, 0, 0.5);
    }
    100% {
        box-shadow: 0 0 20px rgba(255, 215, 0, 0.7), 
                   0 0 40px rgba(255, 215, 0, 0.5),
                   0 0 60px rgba(255, 215, 0, 0.3);
    }
}

/* Text animation that syncs with the main animation but with additional pulse */
@keyframes textGrowth {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    /* Appear with circle */
    10% {
        transform: scale(1);
        opacity: 1;
    }
    /* Pulse while visible */
    25% {
        transform: scale(1.3);
    }
    40% {
        transform: scale(1);
    }
    55% {
        transform: scale(1.3);
    }
    /* Start fading with circle */
    70% {
        transform: scale(1);
        opacity: 1;
    }
    /* Fade out with circle */
    100% {
        transform: scale(0);
        opacity: 0;
    }
}